<!-- 忘记密码页面 -->
<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="step == 1 ? $router.go(-1) : (step = step - 1)"
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("忘记密码") }}
      </span>
    </template>
  </headBackBar>
  <!-- 忘记密码区域 -->
  <div class="Forgetyourpassword">
    <div class="main">
      <div class="Verificationmode" v-if="step == 1">
        <h2>{{ $fanyi("请选择认证方式") }}</h2>
        <div class="moble" @click="(step = 2), (mode = 'mobile')">
          {{ $fanyi("手机号认证") }}
        </div>
        <div class="emal" @click="(step = 2), (mode = 'email')">
          {{ $fanyi("邮箱认证") }}
        </div>
      </div>
      <!-- 获取验证码 -->
      <div class="Verificationmodeform" v-if="step == 2">
        <van-form ref="formRulesooo">
          <!-- 登录名 -->
          <van-cell-group inset>
            <!-- 电话 -->
            <van-field
              v-if="mode == 'mobile'"
              v-model="mobile"
              @blur="validateField('mobile', 'formRulesooo')"
              :class="{ errinput: formRules.mobile == 0 }"
              label-align="top"
              required
              type="number"
              name="mobile"
              :label="$fanyi('手机号')"
              :placeholder="$fanyi('请输入手机号')"
              :rules="[
                {
                  required: true,
                  message: $fanyi('手机号不能为空'),
                  trigger: 'onBlur',
                },

                {
                  validator: validatormobile,
                  trigger: 'onBlur',
                },
              ]"
            >
              <template #right-icon>
                <van-icon
                  name="cross"
                  v-if="formRules.mobile == 0"
                /> </template
            ></van-field>
            <!-- 通过 validator 进行函数校验 -->
            <van-field
              v-else
              v-model="email"
              @blur="validateField('email', 'formRulesooo')"
              :class="{ errinput: formRules.email == 0 }"
              label-align="top"
              required
              :label="$fanyi('电子邮箱')"
              name="email"
              :placeholder="$fanyi('请输入电子邮箱')"
              :rules="[
                {
                  validator: validatoremail,
                  trigger: [' onBlur', ' onChange'],
                },
                {
                  type: 'email',
                  trigger: 'onBlur',
                  required: true,
                  message: $fanyi('请输入正确的邮箱地址'),
                },
              ]"
            >
              <template #right-icon>
                <van-icon name="cross" v-if="formRules.email == 0" /> </template
            ></van-field>
            <!-- 邮箱 -->
            <!-- 未完成    type="number" 因为测试暂时注释 -->
            <!-- 验证码 -->
            <van-field
              v-model="code"
              :class="{ errinput: formRules.code == 0 }"
              @blur="validateField('code', 'formRulesooo')"
              label-align="top"
              :label="$fanyi('验证码')"
              required
              name="code"
              :placeholder="$fanyi('')"
              :rules="[
                {
                  required: true,
                  message: $fanyi('请填写验证码'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatorcode,
                  trigger: 'onBlur',
                },
              ]"
            >
              <template #right-icon>
                <van-icon
                  class="code"
                  name="cross"
                  v-if="formRules.code == 0"
                />
                <div class="yanzhengma" v-if="show" @click.stop="sendSMS">
                  {{ $fanyi("发送验证码") }}
                </div>
                <div class="yanzhengma" v-else style="padding-right: 20px">
                  {{ Countdown }}s
                </div>
              </template></van-field
            >
            <!-- 通过 validator 进行函数校验 -->
          </van-cell-group>
        </van-form>

        <!-- 下一步 -->
        <div class="nextstep" @click="nextstep">{{ $fanyi("下一步") }}</div>
      </div>
      <!-- 修改新密码 -->
      <div class="Changepassword" v-if="step == 3">
        <h2>{{ $fanyi("修改新密码") }}</h2>

        <van-form>
          <van-cell-group inset>
            <van-field
              v-model="password"
              :type="passwordtype"
              name="password"
              :placeholder="$fanyi('请输入新密码')"
              :rules="[
                {
                  required: true,
                  message: $fanyi('请输入 6 到 16 位的密码,不支持空格'),
                },
              ]"
            >
              <template #right-icon>
                <img
                  v-if="passwordtype == 'password'"
                  @click="passwordtype = 'text'"
                  src="../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                  alt=""
                />
                <img
                  v-else
                  @click="passwordtype = 'password'"
                  src="../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                  alt=""
                /> </template
            ></van-field>
            <van-field
              v-model="word"
              :class="{ errinput: formRules.word == 0 }"
              :type="wordtype"
              name="word"
              :placeholder="$fanyi('请确认密码')"
              :rules="[
                { required: true, message: '请确认密码' },
                {
                  validator: validatorword,
                  trigger: 'onBlur',
                },
              ]"
            >
              <template #right-icon>
                <van-icon
                  class="code"
                  name="cross"
                  v-if="formRules.word == 0"
                />
                <img
                  v-if="wordtype == 'password'"
                  @click="wordtype = 'text'"
                  src="../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                  alt=""
                />
                <img
                  v-else
                  @click="wordtype = 'password'"
                  src="../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                  alt=""
                /> </template
            ></van-field>
          </van-cell-group>
        </van-form>
        <!-- 下一步 -->
        <div class="nextstep" @click="Changepassword">
          {{ $fanyi("下一步") }}
        </div>
      </div>
      <!-- 修改成功 -->
      <div class="successfully" v-if="step == 4">
        <div class="content">
          <div class="top-title">
            <img src="../../assets/register/dui.png" alt="" />
          </div>
          <div class="top-text">
            <p>{{ $fanyi("登录密码修改成功") }}</p>
          </div>
          <div class="gologin" @click="goindex()">{{ $fanyi("返回登录") }}</div>
          <div class="goindex" @click="goindex('index')">
            {{ $fanyi("回到首页") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import headBackBar from "../../components/headGoBack";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
const step = ref(1);
const mode = ref(null);
const mobile = ref("");
const formRulesooo = ref(null);
const email = ref("");
const code = ref("");
const formRules = ref({
  mobile: 1,
  email: 1,
  code: 1,
  password: 1,
  word: 1,
});
// 电子邮箱校验
const validatoremail = async (val, target) => {
  if (
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi("邮箱错误");
  }
  let res = await proxy.$api.checkRegisterField({
    field: "email",
    value: val,
  });
  // 返回true说明没有在我们这注册过
  if (res.success == true) {
    formRules.value.email = 0;
    return proxy.$fanyi("没有注册账号");
  }
  if (res.success == false) {
    // email.value = "";
    formRules.value.email = 0;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("邮箱格式不正确，请输入正确的邮箱格式");
    }
  }
};
// 手机号校验
const validatormobile = async (val, target) => {
  // if (/^(\w){11}$/.test(val) === false) {
  //   return proxy.$fanyi("您输入的手机号格式不正确，请输入正确的手机号");
  // }

  let res = await proxy.$api.checkRegisterField({
    field: "mobile",
    value: val,
  });
  if (res.success == true) {
    formRules.value.mobile = 0;
    return proxy.$fanyi("没有注册账号");
  }
  if (res.success == false) {
    //mobile.value = "";
    formRules.value.mobile = 0;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("您输入的手机号格式不正确，请输入正确的手机号");
    }
  }
};
// 失去焦点校验表单 然后改变状态
const validateField = (type, name) => {
  formRulesooo.value
    .validate(type)
    .then((res) => {
      formRules.value[type] = 1;
    })
    .catch(() => {
      formRules.value[type] = 0;
    });
};
// 验证码校验
const validatorcode = async (val) => {
  if (!val) return Toast(proxy.$fanyi("请输入验证码"));
  const res = await proxy.$api.checkForgetPasswordCode({
    number: mode.value == "mobile" ? mobile.value : email.value,
    code: code.value,
  });
  if (res.code !== 0) {
    formRules.value.code = 0;
    return proxy.$fanyi(res.msg);
  } else {
    formRules.value.code = 1;
  }
};
// 定义倒计时数据
const show = ref(true);
const Countdown = ref(60);
const sendSMS = () => {
  if (mode.value == "mobile" && mobile.value === "") {
    Toast(proxy.$fanyi("请输入手机号"));

    return false;
  }
  if (mode.value == "email" && email.value === "") {
    Toast(proxy.$fanyi("请输入邮箱"));
    return false;
  }
  let list = {
    number: mode.value == "email" ? email.value : mobile.value,
  };
  Toast.loading({
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  proxy.$api.getForgetPasswordVerificationCode(list).then((res) => {
    if (res.code != 0) return Toast.fail(proxy.$fanyi(res.msg));
    show.value = false;
    // 60秒倒计时
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        show.value = true;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
  });
};
const nextstep = () => {
  proxy.$api
    .checkForgetPasswordCode({
      number: mode.value == "mobile" ? mobile.value : email.value,
      code: code.value,
    })
    .then((res) => {
      if (res.code !== 0) {
        formRules.value.code = 0;
        return Toast(proxy.$fanyi(res.msg));
      } else {
        step.value = 3;
      }
    });
};

// 第三部修改密码数据

const password = ref("");
const word = ref("");
const passwordtype = ref("password");
const wordtype = ref("password");
// 确认密码校验
const validatorword = (val) => {
  if (!val) {
    formRules.value.word = 0;
    return proxy.$fanyi("请输入密码");
  }
  if (val !== password.value) {
    formRules.value.word = 0;
    return proxy.$fanyi("两次密码输入不一致");
  }
  formRules.value.word = 1;
};
const Changepassword = async () => {
  // 发送修改密码请求
  if (password.value == "" || word.value == "") {
    return Toast(proxy.$fanyi("请先输入密码"));
  } else if (password.value != word.value) {
    return Toast(proxy.$fanyi("两次密码输入不一致"));
  } else {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
      loadingType: "spinner",
    });
    let list = {
      new_password: word.value,
      code: code.value ? code.value : "",
      number: mode.value == "mobile" ? mobile.value : email.value,
    };
    const res = await proxy.$api.resetForgetPassword(list);

    Toast.clear();
    if (res.code != 0) return Toast(proxy.$fanyi(res.msg));
    step.value = 4;
  }
};
// 注册完成后去首页登录
const goindex = (type) => {
  if (type == "index") {
    proxy.$fun.toPage("/");
  } else {
    proxy.$store.commit("changeshowLoginDia", () => {
      proxy.$fun.toPage("/");
    });
  }
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

:deep().van-icon-arrow-left {
  font-size: 35px;

  font-weight: 600;
  color: #333333;
}

div {
  box-sizing: border-box;
}

.Forgetyourpassword {
  width: 750px;
  min-height: 1400px;
  background: #f6f6f6;
  padding: 20px 30px 30px;
  padding-bottom: 0px;
  box-sizing: border-box;

  .main {
    height: 1380px;
    width: 690px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 6px;

    .Verificationmode {
      width: 690px;
      padding: 60px;

      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 570px;
        height: 70px;
        background: #b4272b;
        border-radius: 6px;
        margin-bottom: 30px;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
      }
    }

    .Verificationmodeform {
      width: 690px;
      padding: 60px;

      .van-cell {
        display: inline-block;
        padding: 0;

        :deep().van-field__label {
          white-space: nowrap;
          font-size: 28px;
          font-weight: 400;
          color: #999999 !important;
          margin-bottom: 12px;
        }
      }

      .van-cell:after {
        border: none;
      }

      .errinput {
        :deep() input {
          background: #faf2f2 !important;
          border: 1px solid #b4272b !important;
          border-radius: 6px;
        }
      }

      :deep() .van-cell-group {
        margin: 0;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 38px;

        .van-icon-cross {
          font-size: 24px;
          color: #b4272b;
          font-weight: 600;
        }
      }

      .code {
        position: absolute;

        right: 261px;
      }

      .yanzhengma {
        font-size: 28px;

        font-weight: 400;
        color: #b4272b;
      }

      :deep().van-cell__value {
        margin-top: 10px;
        min-height: 110px;
        border: none;

        input {
          font-size: 28px;

          font-weight: 400;
          color: #000000;
          padding-left: 20px;
        }
      }

      :deep().van-field__error-message {
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 400;
        color: #b4272b;
        padding-left: 23px;
        min-height: 40px;
        border: none;
      }
    }

    .nextstep {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 570px;
      height: 70px;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
      background: #b4272b;
      border-radius: 6px;
    }

    .Changepassword {
      width: 690px;
      padding: 60px;

      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      :deep().van-field__body {
        position: relative;
      }

      :deep().van-field__right-icon {
        position: absolute;
        right: 27px;
      }

      .van-cell {
        padding: 0;
      }

      .errinput {
        :deep() input {
          background: #faf2f2 !important;
          border: 1px solid #b4272b !important;
          border-radius: 6px;
        }
      }

      .van-cell-group {
        margin: 0;
        padding-top: 8px;
      }

      .van-icon-cross {
        position: absolute;
        font-size: 24px;
        color: #b4272b;
        font-weight: 600;
        right: 94px;
      }

      :deep().van-cell__value {
        margin-top: 10px;
        height: 110px;
        border: none;

        input {
          font-size: 28px;

          font-weight: 400;
          color: #000000;
          padding-left: 20px;
        }
      }

      :deep() .van-field__right-icon {
        img {
          width: 24px;
          height: 16px;
        }
      }

      //   border: none;
      // }

      .errinput {
        :deep() input {
          background: #faf2f2 !important;
          border: 1px solid #b4272b !important;
          border-radius: 6px;
        }
      }

      :deep().van-field__error-message {
        font-size: 20px;

        font-weight: 400;
        color: #b4272b;
        padding-left: 23px;
        height: 40px;
        border: none;
      }
    }

    .successfully {
      width: 690px;
      padding: 60px;

      .content {
        .top-title {
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            width: 80px;
            height: 80px;
          }

          margin-bottom: 41px;
        }

        .top-text {
          width: 100%;
          text-align: center;

          p {
            width: 100%;

            &:first-child {
              font-size: 32px;

              font-weight: 600;
              color: #000000;
              margin-bottom: 107px;
            }
          }
        }

        .gologin {
          width: 570px;
          height: 70px;
          background: #b4272b;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          margin-top: 66px;
          font-size: 28px;

          font-weight: 400;
          color: #ffffff;
        }

        .goindex {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          margin-top: 30px;
          width: 570px;
          height: 70px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 28px;

          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
}
</style>
